
  import {Vue, Component} from 'vue-property-decorator';
  import { Upload } from '../api/ApiPath';

@Component
export default class Error extends Vue{
    selectedFile: File = null;
    imageUrl:string = "";

    handleFileUpload(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        if (inputElement.files && inputElement.files.length>0) {
            this.selectedFile = inputElement.files[0];
        }
        const file = this.selectedFile
    console.log(file);
    const formData = new FormData();
        formData.append('file', file); 
console.log(formData);
    }
    uploadImage() {
        // const fileInput = this.$refs.fileInput as HTMLInputElement;
    // console.log(this.selectedFile);
  
//         const file = this.selectedFile
//     console.log(file);
//         formData.append('file', file); 
// console.log(formData);
//         Upload(formData, (res)=>{
//             let {status, msg} = res
//             this.$message.success(msg);
//         },(err)=>{this.$message.error(err)})
    }
  };
  